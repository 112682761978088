import { useEffect, useState } from 'react'

import Icon from 'components/Icon'
import SpinningLoader from 'components/SpinningLoader'
import { useTranslations } from 'contexts/i18n'

const LoadingPage = () => {
  const [phraseIndex, setPhraseIndex] = useState(0)
  const translations = useTranslations()

  const PHRASES = [
    translations.SPECIAL_PAGES.LOADING_PHRASE_1,
    translations.SPECIAL_PAGES.LOADING_PHRASE_2,
    translations.SPECIAL_PAGES.LOADING_PHRASE_3,
    translations.SPECIAL_PAGES.LOADING_PHRASE_4,
  ]

  useEffect(() => {
    const interval = window.setInterval(() => {
      setPhraseIndex((phraseIndex) => phraseIndex + 1)
    }, 4000)

    return () => {
      window.clearInterval(interval)
    }
  }, [])

  return (
    <div className="flex h-full flex-col items-center justify-center space-y-6 text-center text-gray-700 md:px-8">
      <div className="w-36">
        <Icon id="survey-loading" />
      </div>

      {/* Note: The animation duration here matches the interval for changing the phrase. */}
      <p className="animate-[4s_ease-in_infinite_text-in] text-2xl font-semibold">
        {PHRASES[phraseIndex % PHRASES.length]}
      </p>

      <SpinningLoader />
    </div>
  )
}

export default LoadingPage
