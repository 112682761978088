import { useCallback, useEffect, useRef } from 'react'
import { useMachine } from '@xstate/react'
import { useParams, useSearchParams } from 'react-router-dom'

import {
  areParametersValid,
  getRespondentID,
  redirectCompleted,
  redirectDisqualified,
} from 'utils/panelProviders'
import { createSurveyMachine, getSurveyTimeToComplete } from 'machines/survey'
import { getAnswersToSubmitToAPI } from 'utils/answers'
import {
  getDisplayedQuestionsWithMonadicConceptIDs,
  getEndMessages,
  isCurrentQuestionFirst,
  isCurrentQuestionLast,
} from 'utils/surveys'
import { getIDParts, getResponsesArray } from 'utils/questions'
import { getReadableDisqualificationReason } from 'utils/disqualifications'
import { hasResponseStatusCode } from 'utils/api'
import { I18NProvider, useTranslations } from 'contexts/i18n'
import { Survey } from 'types/glass-api/domainModels'
import { useDisqualifyRespondent } from 'hooks/glass-api/respondents'
import { useSubmitAnswers, useSurvey } from 'hooks/glass-api/surveys'

import BadRequestPage from './BadRequestPage'
import BipolarQuestion from 'components/BipolarQuestion'
import GaborGrangerQuestion from 'components/GaborGrangerQuestion'
import IdeaPresenterQuestion from 'components/IdeaPresenterQuestion'
import LoadingPage from './LoadingPage'
import MatrixQuestion from 'components/MatrixQuestion'
import MultipleChoiceQuestion from 'components/MultipleChoiceQuestion'
import NotFoundPage from './NotFoundPage'
import OpenEndedQuestion from 'components/OpenEndedQuestion'
import OverlayCompleted from 'components/OverlayCompleted'
import OverlayDisqualified from 'components/OverlayDisqualified'
import OverlaySurveyClosed from 'components/OverlaySurveyClosed'
import RankingQuestion from 'components/RankingQuestion'
import ScaleQuestion from 'components/ScaleQuestion'
import SurveyWithTesting from 'components/SurveyWithTesting'
import TestingHeader from 'components/TestingHeader'
import UnaidedAwarenessQuestion from 'components/UnaidedAwarenessQuestion'
import UnexpectedErrorPage from './UnexpectedErrorPage'

const SurveyPage = () => {
  const { surveyHash } = useParams()

  const [searchParams] = useSearchParams()

  const {
    data: survey,
    error: loadSurveyError,
    isError: hasLoadSurveyError,
    isLoading: isLoadingSurvey,
  } = useSurvey({ surveyHash })
  const surveyNotFound =
    !surveyHash ||
    (hasLoadSurveyError && hasResponseStatusCode(loadSurveyError, 404))

  if (surveyNotFound) {
    return <NotFoundPage />
  }

  if (!areParametersValid({ searchParams })) {
    return <BadRequestPage />
  }

  return (
    <I18NProvider language={survey?.language || 'EN'}>
      {survey ? (
        <div className="h-full">
          <SurveyLoaded searchParams={searchParams} survey={survey} />
        </div>
      ) : isLoadingSurvey ? (
        <LoadingPage />
      ) : (
        <UnexpectedErrorPage />
      )}
    </I18NProvider>
  )
}

export default SurveyPage

const SurveyLoaded = ({
  searchParams,
  survey: surveyAPI,
}: {
  searchParams: URLSearchParams
  survey: Survey
}) => {
  const translations = useTranslations()

  const surveyContentDiv = useRef<HTMLDivElement | null>(null)

  const { mutateAsync: saveAnswers } = useSubmitAnswers({ retry: 1 })
  const { mutateAsync: disqualifyRespondent } = useDisqualifyRespondent()

  const [state, send] = useMachine(
    () =>
      createSurveyMachine({
        isTestMode: searchParams.get('mode') === 'testing',
        respondentID: getRespondentID({ searchParams }),
        startingQuestionID: searchParams.get('startingQuestionID') ?? undefined,
        surveyAPI,
      }),
    {
      actions: {
        redirectCompleted: (ctx) => {
          redirectCompleted({ isTesting: ctx.isTesting, searchParams })
        },
        redirectDisqualified: (ctx) => {
          redirectDisqualified({
            disqualificationReason: ctx.disqualificationReason,
            isTesting: ctx.isTesting,
            searchParams,
          })
        },
      },
      services: {
        saveAnswers: async (ctx) => {
          const questionsToSubmit = getDisplayedQuestionsWithMonadicConceptIDs({
            monadicBlocks: ctx.monadicBlocks,
            questions: ctx.questionsCurrent,
            surveyItems: ctx.surveyItems,
          })

          return saveAnswers({
            data: {
              answers: getAnswersToSubmitToAPI({
                questions: questionsToSubmit,
              }),
              timeToComplete: getSurveyTimeToComplete(ctx),
            },
            surveyHash: ctx.survey.hash,
          })
        },
        trackDisqualification: async (ctx) => {
          const { curQuestionID, disqualificationReason, survey, userID } = ctx

          if (!userID) {
            throw new Error('Cannot track disqualification: No respondent ID')
          }

          if (!disqualificationReason) {
            throw new Error('Cannot track disqualification: No reason provided')
          }

          return disqualifyRespondent({
            data: {
              questionId: Number(getIDParts({ id: curQuestionID })[0]),
              source: disqualificationReason.source,
              surveyHash: survey.hash,
              tactic: disqualificationReason.tactic,
              waveId: Number(survey.waveID),
            },
            respondentID: userID,
          })
        },
      },
    },
  )
  const {
    curQuestionID,
    disqualificationReason,
    isTesting,
    monadicBlocks,
    questionsCurrent,
    questionsInitial,
    survey,
    surveyItems,
    userID,
  } = state.context
  const curQuestion = questionsCurrent[curQuestionID]
  const isFirstQuestion = isCurrentQuestionFirst({ curQuestionID, surveyItems })
  const isLastQuestion = isCurrentQuestionLast({ curQuestionID, surveyItems })

  const onClickNext = useCallback(() => {
    send({ type: 'CLICKED_TEST_NEXT' })
  }, [send])

  const onClickBack = useCallback(() => {
    send({ type: 'CLICKED_TEST_PREVIOUS' })
  }, [send])

  const onClickCloseOverlay = useCallback(() => {
    send({ type: 'CLICKED_CLOSE_OVERLAY' })
  }, [send])

  const onPaste = useCallback(() => {
    send({ type: 'COPY_PASTED' })
  }, [send])

  const onViewedConcept = useCallback(
    (questionID: string, conceptID: string) => {
      send({ conceptID, questionID, type: 'VIEWED_CONCEPT' })
    },
    [send],
  )

  // The user may have scrolled down the page to answer the previous question.
  // When we go to the next one, we'd like to bring them back to the top of the
  // page.
  useEffect(() => {
    if (surveyContentDiv) {
      surveyContentDiv.current?.scrollTo(0, 0)
    }
  }, [curQuestion.id])

  const testingHeader = isTesting ? (
    <TestingHeader
      disabledNext={isLastQuestion}
      disabledPrevious={isFirstQuestion}
      onClickNext={onClickNext}
      onClickPrevious={onClickBack}
    />
  ) : undefined

  // We key questions by their current response in test mode in case the user clears the
  // response for the question. This will cause a re-render of the question component and a
  // clearing of any nested form state (since each question manages its own form state until
  // the "Next" button is clicked).
  const curResponseStr = getResponsesArray({ question: curQuestion }).join('-')
  const questionKey = isTesting
    ? `${curQuestion.id}-${curResponseStr}`
    : curQuestion.id

  const buttonText = survey.customizations.buttonText
  const endMessages = getEndMessages({ survey, translations })

  const surveyContent = (
    <div
      ref={surveyContentDiv}
      className="relative h-full w-full overflow-auto p-6 pb-32 md:p-4 md:pb-32"
    >
      <div className="mx-auto max-w-[800px] space-y-6">
        {testingHeader}

        {curQuestion.type === 'bipolar' ? (
          <BipolarQuestion
            key={questionKey}
            buttonText={buttonText}
            isLastQuestion={isLastQuestion}
            onCompletedQuestion={(data) => {
              send({
                newQuestion: {
                  ...curQuestion,
                  response: data.response,
                },
                type: 'COMPLETED_QUESTION',
              })
            }}
            onViewedConcept={onViewedConcept}
            question={curQuestion}
          />
        ) : curQuestion.type === 'gaborGranger' ? (
          <GaborGrangerQuestion
            key={questionKey}
            buttonText={buttonText}
            isLastQuestion={isLastQuestion}
            onCompletedQuestion={(data) => {
              send({
                newQuestion: {
                  ...curQuestion,
                  response: data.response,
                },
                type: 'COMPLETED_QUESTION',
              })
            }}
            onViewedConcept={onViewedConcept}
            question={curQuestion}
          />
        ) : curQuestion.type === 'ideaPresenter' ? (
          <IdeaPresenterQuestion
            key={questionKey}
            buttonText={buttonText}
            isLastQuestion={isLastQuestion}
            onCompletedQuestion={() => {
              send({
                newQuestion: { ...curQuestion, response: 'completed' },
                type: 'COMPLETED_QUESTION',
              })
            }}
            onViewedConcept={onViewedConcept}
            question={curQuestion}
          />
        ) : curQuestion.type === 'matrix' ? (
          <MatrixQuestion
            key={questionKey}
            buttonText={buttonText}
            isLastQuestion={isLastQuestion}
            onCompletedQuestion={(data) => {
              send({
                newQuestion: {
                  ...curQuestion,
                  response: data.response,
                  responseFreeText: data.responseFreeText,
                },
                type: 'COMPLETED_QUESTION',
              })
            }}
            onPaste={onPaste}
            onViewedConcept={onViewedConcept}
            question={curQuestion}
          />
        ) : curQuestion.type === 'multipleChoice' ? (
          <MultipleChoiceQuestion
            key={questionKey}
            buttonText={buttonText}
            isLastQuestion={isLastQuestion}
            onCompletedQuestion={(data) => {
              send({
                newQuestion: {
                  ...curQuestion,
                  response: data.response,
                  responseFreeText: data.responseFreeText,
                },
                type: 'COMPLETED_QUESTION',
              })
            }}
            onPaste={onPaste}
            onViewedConcept={onViewedConcept}
            onViewedImage={(optionID) => {
              send({
                optionID,
                questionID: curQuestion.id,
                type: 'VIEWED_IMAGE',
              })
            }}
            question={curQuestion}
          />
        ) : curQuestion.type === 'openEnded' ? (
          <OpenEndedQuestion
            key={questionKey}
            buttonText={buttonText}
            isLastQuestion={isLastQuestion}
            onCompletedQuestion={(data) => {
              send({
                newQuestion: {
                  ...curQuestion,
                  response: data.response,
                },
                type: 'COMPLETED_QUESTION',
              })
            }}
            onPaste={onPaste}
            onViewedConcept={onViewedConcept}
            question={curQuestion}
            userID={userID}
          />
        ) : curQuestion.type === 'ranking' ? (
          <RankingQuestion
            key={questionKey}
            buttonText={buttonText}
            isLastQuestion={isLastQuestion}
            onCompletedQuestion={(data) => {
              send({
                newQuestion: {
                  ...curQuestion,
                  response: data.response,
                },
                type: 'COMPLETED_QUESTION',
              })
            }}
            onViewedConcept={onViewedConcept}
            onViewedImage={(optionID) => {
              send({
                optionID,
                questionID: curQuestion.id,
                type: 'VIEWED_IMAGE',
              })
            }}
            question={curQuestion}
          />
        ) : curQuestion.type === 'scale' ? (
          <ScaleQuestion
            key={questionKey}
            buttonText={buttonText}
            isLastQuestion={isLastQuestion}
            onCompletedQuestion={(data) => {
              send({
                newQuestion: {
                  ...curQuestion,
                  response: data.response,
                },
                type: 'COMPLETED_QUESTION',
              })
            }}
            onViewedConcept={onViewedConcept}
            question={curQuestion}
          />
        ) : curQuestion.type === 'unaidedAwareness' ? (
          <UnaidedAwarenessQuestion
            key={questionKey}
            buttonText={buttonText}
            isLastQuestion={isLastQuestion}
            onCompletedQuestion={(data) => {
              send({
                newQuestion: {
                  ...curQuestion,
                  response: data.response,
                },
                type: 'COMPLETED_QUESTION',
              })
            }}
            onPaste={onPaste}
            onViewedConcept={onViewedConcept}
            question={curQuestion}
          />
        ) : null}
      </div>

      {/*
       * We show the survey closed overlay for a wave_quota disqualification because that's what that disqualification
       * means. If we don't and a redirect happens, the disqualified overlay will show. At some point we should refactor the
       * XState survey machine so we stay in a survey closed state even when disqualification / redirects are happening.
       */}
      {state.matches('surveyClosed') ||
      disqualificationReason?.tactic === 'WAVE_QUOTA' ? (
        <OverlaySurveyClosed
          {...endMessages.closed}
          onClose={isTesting ? onClickCloseOverlay : undefined}
        />
      ) : state.matches('testingCompleted') ||
        state.matches('savingAnswers') ||
        state.matches('completed') ||
        state.matches('redirectingCompleted') ? (
        <OverlayCompleted
          {...endMessages.completed}
          onClose={isTesting ? onClickCloseOverlay : undefined}
          savingPrompt={translations.UI_ELEMENTS.SAVING_RESPONSES}
          step={state.matches('savingAnswers') ? 'saving' : 'saved'}
        />
      ) : state.matches('disqualified') ||
        state.matches('trackDisqualification') ||
        state.matches('redirectingDisqualified') ? (
        <OverlayDisqualified
          {...endMessages.disqualified}
          explanation={
            isTesting
              ? getReadableDisqualificationReason(disqualificationReason)
              : undefined
          }
          onClose={isTesting ? onClickCloseOverlay : undefined}
        />
      ) : null}
    </div>
  )

  if (isTesting) {
    return (
      <SurveyWithTesting
        curQuestionID={curQuestionID}
        monadicBlocks={monadicBlocks}
        onClickClearResponse={(opts) => {
          send({ ...opts, type: 'CLICKED_CLEAR_RESPONSE' })
        }}
        onClickQuestion={(questionID) => {
          send({ questionID, type: 'JUMP_TO_QUESTION' })
        }}
        questionsCurrent={questionsCurrent}
        questionsInitial={questionsInitial}
        survey={survey}
        surveyItems={surveyItems}
      >
        {surveyContent}
      </SurveyWithTesting>
    )
  }

  return surveyContent
}
