import ExceptionPage from 'components/ExceptionPage'
import { useTranslations } from 'contexts/i18n'

const UnexpectedErrorPage = () => {
  const translations = useTranslations()
  return (
    <ExceptionPage title={translations.ERRORS.UNEXPECTED_ERROR}>
      {translations.ERRORS.UNEXPECTED_ERROR_MESSAGE}
    </ExceptionPage>
  )
}

export default UnexpectedErrorPage
