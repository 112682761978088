import { Language } from 'types/glass-api/domainModels'

export type Translations = {
  ACCESSIBILITY: {
    CLOSE_DIALOG: string
    CLOSE_OVERLAY: string
    FREE_TEXT_PLACEHOLDER: string
    MATRIX_FREE_TEXT_LABEL: (opts: {
      option: string
      statement: string
    }) => string
    MC_FREE_TEXT_LABEL: (option: string) => string
    RESPONSE_FOR_LINE: (line: number) => string
    ZOOM_OPTION: (option: string) => string
  }
  CUSTOM_END_MESSAGES: {
    APPRECIATE_YOUR_INTEREST: string
    SORRY_YOU_DID_NOT_QUALIFY: string
    THANK_YOU: string
    THANKS_FOR_COMPLETING: string
    YOU_ARE_DONE: string
    YOU_DID_NOT_QUALIFY_IN_THE_AUDIENCE: string
  }
  ERRORS: {
    FREE_TEXT_MAX: (max: number) => string
    FREE_TEXT_MIN: (min: number) => string
    MATRIX_FREE_TEXT_MISSING: (option: string) => string
    MATRIX_FREE_TEXT_MISSING_HEADER: string
    MC_FREE_TEXT_MISSING: (option: string) => string
    UNEXPECTED_ERROR: string
    UNEXPECTED_ERROR_MESSAGE: string
    VALIDATION_EMAIL: string
    VALIDATION_ZIP: string
  }
  SPECIAL_PAGES: {
    LOADING_PHRASE_1: string
    LOADING_PHRASE_2: string
    LOADING_PHRASE_3: string
    LOADING_PHRASE_4: string
  }
  UI_ELEMENTS: {
    BUTTON_DONE: string
    BUTTON_NEXT: string
    GABOR_GRANGER_BUTTON_NO: string
    GABOR_GRANGER_BUTTON_YES: string
    GABOR_GRANGER_HOW_ABOUT: string
    GABOR_GRANGER_THANKS: string
    IMAGE_OVERLAY_CLICK: string
    IMAGE_OVERLAY_CLOSE: string
    IMAGE_OVERLAY_SCROLL: string
    OPEN_ENDED_VIDEO_STOP_RECORDING: string
    REQUIRED_SUM_TOTAL: string
    SAVING_RESPONSES: string
  }
}

export const TRANSLATIONS = {
  DE: {
    ACCESSIBILITY: {
      CLOSE_DIALOG: 'Dialog schließen',
      CLOSE_OVERLAY: 'Overlay schließen',
      FREE_TEXT_PLACEHOLDER: 'Beginnen Sie hier mit der Eingabe ...',
      MATRIX_FREE_TEXT_LABEL: (opts) =>
        `Antwort für ${opts.statement} - ${opts.option}`,
      MC_FREE_TEXT_LABEL: (option) => `Antwort für ${option}`,
      RESPONSE_FOR_LINE: (line) => `Antwort für Zeile ${line}`,
      ZOOM_OPTION: (option) => `Zoom ${option}`,
    },
    CUSTOM_END_MESSAGES: {
      APPRECIATE_YOUR_INTEREST:
        'Wir freuen uns über Ihr Interesse an dieser Umfrage. Derzeit werden jedoch keine Antworten angenommen.',
      SORRY_YOU_DID_NOT_QUALIFY:
        'Entschuldigung, Sie haben sich nicht qualifiziert',
      THANK_YOU: 'Danke',
      THANKS_FOR_COMPLETING:
        'Vielen Dank, dass Sie an dieser Umfrage teilgenommen haben.',
      YOU_ARE_DONE: 'Sie sind fertig!',
      YOU_DID_NOT_QUALIFY_IN_THE_AUDIENCE:
        'Sie haben sich nicht für die Zielgruppe dieser Kampagne qualifiziert.',
    },
    ERRORS: {
      FREE_TEXT_MAX: (max) => `Muss höchstens sein ${max}.`,
      FREE_TEXT_MIN: (min) => `Muss mindestens sein ${min}.`,
      MATRIX_FREE_TEXT_MISSING: (statement) =>
        `Bitte geben Sie für ${statement} eine Antwort an.`,
      MATRIX_FREE_TEXT_MISSING_HEADER:
        'Bitte bearbeiten Sie Ihre Antworten zu den folgenden Aussagen:',
      MC_FREE_TEXT_MISSING: (option) =>
        `Bitte geben Sie für ${option} eine Antwort an.`,
      UNEXPECTED_ERROR: 'Etwas ist schief gelaufen',
      UNEXPECTED_ERROR_MESSAGE:
        'Wir entschuldigen uns für die Unannehmlichkeiten. Beim Laden Ihrer Umfrage ist ein Problem aufgetreten. Seien Sie versichert, wir prüfen das.',
      VALIDATION_EMAIL: 'Muss eine gültige E-Mail sein',
      VALIDATION_ZIP: 'Muss eine gültige Postleitzahl sein',
    },
    SPECIAL_PAGES: {
      LOADING_PHRASE_1: 'Bleib dran! Ihre Umfrage wird geladen.',
      LOADING_PHRASE_2: 'Fast geschafft! Vorbereitung Ihrer Umfrage.',
      LOADING_PHRASE_3: 'Einen Moment, wir bereiten Ihre Umfrage vor.',
      LOADING_PHRASE_4:
        'Ihre Umfrage ist unterwegs! Vielen Dank für Ihre Geduld.',
    },
    UI_ELEMENTS: {
      BUTTON_DONE: 'Erledigt',
      BUTTON_NEXT: 'Nächste',
      GABOR_GRANGER_BUTTON_NO: 'NEIN',
      GABOR_GRANGER_BUTTON_YES: 'Ja',
      GABOR_GRANGER_HOW_ABOUT: 'Wie wäre es mit...',
      GABOR_GRANGER_THANKS: 'Vielen Dank für Ihre Antwort!',
      IMAGE_OVERLAY_CLICK: 'Klicken/tippen Sie zum Anzeigen',
      IMAGE_OVERLAY_CLOSE: 'Tippen Sie zum Schließen außerhalb des Bildes.',
      IMAGE_OVERLAY_SCROLL:
        'Zum Vergrößern scrollen/pinzetten oder doppelklicken Sie auf das Bild.',
      OPEN_ENDED_VIDEO_STOP_RECORDING:
        'Ich habe technische Probleme oder möchte nicht mehr aufnehmen',
      REQUIRED_SUM_TOTAL: 'Summe:',
      SAVING_RESPONSES: 'Speichern...',
    },
  },
  EN: {
    ACCESSIBILITY: {
      CLOSE_DIALOG: 'Close Dialog',
      CLOSE_OVERLAY: 'Close Overlay',
      FREE_TEXT_PLACEHOLDER: 'Begin typing here...',
      MATRIX_FREE_TEXT_LABEL: (opts) =>
        `Response for ${opts.statement} - ${opts.option}`,
      MC_FREE_TEXT_LABEL: (option) => `Response for ${option}`,
      RESPONSE_FOR_LINE: (line) => `Response for line ${line}`,
      ZOOM_OPTION: (option) => `Zoom ${option}`,
    },
    CUSTOM_END_MESSAGES: {
      APPRECIATE_YOUR_INTEREST:
        'We appreciate your interest in this survey. However, it is not currently accepting responses.',
      SORRY_YOU_DID_NOT_QUALIFY: "Sorry, you didn't qualify",
      THANK_YOU: 'Thank you',
      THANKS_FOR_COMPLETING: 'Thanks for completing this survey.',
      YOU_ARE_DONE: "You're all done!",
      YOU_DID_NOT_QUALIFY_IN_THE_AUDIENCE:
        "You didn't qualify in the audience for this campaign.",
    },
    ERRORS: {
      FREE_TEXT_MAX: (max) => `Must be at most ${max}.`,
      FREE_TEXT_MIN: (min) => `Must be at least ${min}.`,
      MATRIX_FREE_TEXT_MISSING: (statement) =>
        `Please provide a response for ${statement}.`,
      MATRIX_FREE_TEXT_MISSING_HEADER:
        'Please fix your responses for the following statements:',
      MC_FREE_TEXT_MISSING: (option) =>
        `Please provide a response for ${option}.`,
      UNEXPECTED_ERROR: 'Something Went Wrong',
      UNEXPECTED_ERROR_MESSAGE:
        "We apologize for the inconvenience. There was an issue loading your survey. Rest assured, we're looking into it.",
      VALIDATION_EMAIL: 'Must be a valid email',
      VALIDATION_ZIP: 'Must be a valid zip code',
    },
    SPECIAL_PAGES: {
      LOADING_PHRASE_1: 'Hang tight! Your survey is loading.',
      LOADING_PHRASE_2: 'Almost there! Preparing your survey.',
      LOADING_PHRASE_3: "Just a moment, we're getting your survey ready.",
      LOADING_PHRASE_4: 'Your survey is on the way! Thanks for your patience.',
    },
    UI_ELEMENTS: {
      BUTTON_DONE: 'Done',
      BUTTON_NEXT: 'Next',
      GABOR_GRANGER_BUTTON_NO: 'No',
      GABOR_GRANGER_BUTTON_YES: 'Yes',
      GABOR_GRANGER_HOW_ABOUT: 'How about...',
      GABOR_GRANGER_THANKS: 'Thank you for your answer!',
      IMAGE_OVERLAY_CLICK: 'Click/tap to view',
      IMAGE_OVERLAY_CLOSE: 'Tap outside image to close.',
      IMAGE_OVERLAY_SCROLL: 'Scroll/pinch or double-click image to zoom.',
      OPEN_ENDED_VIDEO_STOP_RECORDING:
        "I'm having technical difficulties or would no longer like to record",
      REQUIRED_SUM_TOTAL: 'Total:',
      SAVING_RESPONSES: 'Saving...',
    },
  },
  ES: {
    ACCESSIBILITY: {
      CLOSE_DIALOG: 'Cerrar diálogo',
      CLOSE_OVERLAY: 'Cerrar superposición',
      FREE_TEXT_PLACEHOLDER: 'Comience a escribir aquí...',
      MATRIX_FREE_TEXT_LABEL: (opts) =>
        `Respuesta para ${opts.statement} - ${opts.option}`,
      MC_FREE_TEXT_LABEL: (option) => `Respuesta para ${option}`,
      RESPONSE_FOR_LINE: (line) => `Respuesta para la línea ${line}`,
      ZOOM_OPTION: (option) => `Aumentar ${option}`,
    },
    CUSTOM_END_MESSAGES: {
      APPRECIATE_YOUR_INTEREST:
        'Apreciamos su interés en esta encuesta. Sin embargo, actualmente no acepta respuestas.',
      SORRY_YOU_DID_NOT_QUALIFY: 'Lo siento, no calificaste',
      THANK_YOU: 'Gracias',
      THANKS_FOR_COMPLETING: 'Gracias por completar esta encuesta',
      YOU_ARE_DONE: '¡Ya terminaste!',
      YOU_DID_NOT_QUALIFY_IN_THE_AUDIENCE:
        'No calificaste entre la audiencia para esta campaña.',
    },
    ERRORS: {
      FREE_TEXT_MAX: (max) => `Debe ser como máximo ${max}.`,
      FREE_TEXT_MIN: (min) => `Debe ser al menos ${min}.`,
      MATRIX_FREE_TEXT_MISSING: (option) =>
        `Por favor, proporcione una respuesta para ${option}.`,
      MATRIX_FREE_TEXT_MISSING_HEADER:
        'Por favor, corrija sus respuestas para las siguientes declaraciones:',
      MC_FREE_TEXT_MISSING: (option) =>
        `Por favor, proporcione una respuesta para ${option}.`,
      UNEXPECTED_ERROR: 'Algo salió mal',
      UNEXPECTED_ERROR_MESSAGE:
        'Pedimos disculpas por las molestias. Hubo un problema al cargar tu encuesta. Tenga la seguridad de que lo estamos investigando.',
      VALIDATION_EMAIL: 'Debe ser un correo electrónico válido',
      VALIDATION_ZIP: 'Debe ser un código postal válido',
    },
    SPECIAL_PAGES: {
      LOADING_PHRASE_1: '¡Aguanta fuerte! Tu encuesta se está cargando.',
      LOADING_PHRASE_2: '¡Casi llegamos! Preparando su encuesta.',
      LOADING_PHRASE_3: 'Un momento, estamos preparando su encuesta.',
      LOADING_PHRASE_4:
        '¡Tu encuesta está en camino! Gracias por tu paciencia.',
    },
    UI_ELEMENTS: {
      BUTTON_DONE: 'Finalizar',
      BUTTON_NEXT: 'Próximo',
      GABOR_GRANGER_BUTTON_NO: 'No',
      GABOR_GRANGER_BUTTON_YES: 'Sí',
      GABOR_GRANGER_HOW_ABOUT: '¿Qué tal...?',
      GABOR_GRANGER_THANKS: '¡Gracias por tu respuesta!',
      IMAGE_OVERLAY_CLICK: 'Haga clic/toque para ver',
      IMAGE_OVERLAY_CLOSE: 'Toque la imagen exterior para cerrarla.',
      IMAGE_OVERLAY_SCROLL:
        'Desplácese, pellizque o haga doble clic en la imagen para ampliar.',
      OPEN_ENDED_VIDEO_STOP_RECORDING:
        'Tengo dificultades técnicas o ya no quiero grabar',
      REQUIRED_SUM_TOTAL: 'Total:',
      SAVING_RESPONSES: 'Ahorro...',
    },
  },
  FR: {
    ACCESSIBILITY: {
      CLOSE_DIALOG: 'Fermer la boîte de dialogue',
      CLOSE_OVERLAY: 'Fermer la superposition',
      FREE_TEXT_PLACEHOLDER: 'Commencez à saisir du texte ici...',
      MATRIX_FREE_TEXT_LABEL: (opts) =>
        `Réponse pour ${opts.statement} - ${opts.option}`,
      MC_FREE_TEXT_LABEL: (option) => `Réponse pour ${option}`,
      RESPONSE_FOR_LINE: (line) => `Réponse pour la ligne ${line}`,
      ZOOM_OPTION: (option) => `Agrandir ${option}`,
    },
    CUSTOM_END_MESSAGES: {
      APPRECIATE_YOUR_INTEREST:
        "Nous vous remercions de l'intérêt que vous portez à ce sondage. Cependant, les réponses ne sont pas acceptées à l'heure actuelle.",
      SORRY_YOU_DID_NOT_QUALIFY:
        'Nous sommes désolés, vous ne répondez pas aux critères de participation.',
      THANK_YOU: 'Merci',
      THANKS_FOR_COMPLETING: "Merci d'avoir répondu à ce sondage.",
      YOU_ARE_DONE: 'Vous avez terminé!',
      YOU_DID_NOT_QUALIFY_IN_THE_AUDIENCE:
        'Votre profil ne correspond pas au public recherché pour cette campagne.',
    },
    ERRORS: {
      FREE_TEXT_MAX: (max) => `Doit être ${max} au maximum`,
      FREE_TEXT_MIN: (min) => `Doit être ${min} au minimum`,
      MATRIX_FREE_TEXT_MISSING: (statement) =>
        `Veuillez indiquer une réponse pour ${statement}.`,
      MATRIX_FREE_TEXT_MISSING_HEADER:
        'Veuillez corriger vos réponses pour les énoncés suivants:',
      MC_FREE_TEXT_MISSING: (option) =>
        `Veuillez indiquer une réponse pour ${option}.`,
      UNEXPECTED_ERROR: 'Un problème est survenu',
      UNEXPECTED_ERROR_MESSAGE:
        'Nous nous excusons pour la gêne occasionnée. Un problème est survenu lors du chargement de votre sondage. Rassurez-vous, nous allons régler le problème.',
      VALIDATION_EMAIL: 'Doit être une adresse e-mail valide',
      VALIDATION_ZIP: 'Doit être un code postal valide',
    },
    SPECIAL_PAGES: {
      LOADING_PHRASE_1:
        'Veuillez patienter. Votre sondage est en cours de chargement.',
      LOADING_PHRASE_2: 'Il sera bientôt prêt! Préparation de votre sondage.',
      LOADING_PHRASE_3:
        'Veuillez patienter un petit moment, nous préparons votre sondage.',
      LOADING_PHRASE_4:
        'Votre sondage ne va plus tarder! Merci pour votre patience.',
    },
    UI_ELEMENTS: {
      BUTTON_DONE: 'Terminé',
      BUTTON_NEXT: 'Suivant',
      GABOR_GRANGER_BUTTON_NO: 'Non',
      GABOR_GRANGER_BUTTON_YES: 'Oui',
      GABOR_GRANGER_HOW_ABOUT: 'Que diriez-vous de...',
      GABOR_GRANGER_THANKS: 'Merci pour votre réponse!',
      IMAGE_OVERLAY_CLICK: 'Cliquez/appuyez pour afficher',
      IMAGE_OVERLAY_CLOSE: "Appuyez en dehors de l'image pour fermer.",
      IMAGE_OVERLAY_SCROLL:
        "Faites défiler/pincez ou double-cliquez sur l'image pour l'agrandir.",
      OPEN_ENDED_VIDEO_STOP_RECORDING:
        'Je rencontre des difficultés techniques ou je ne souhaite plus enregistrer',
      REQUIRED_SUM_TOTAL: 'Total:',
      SAVING_RESPONSES: 'Enregistrement en cours...',
    },
  },
} as const satisfies Record<Language, Translations>
